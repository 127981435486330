.App-logo {
  cursor: pointer;
}

.App-header {
  background-color: #ffffff;
  height: 75px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-bottom: 1px solid #C4C4C4;
  overflow: hidden;
}

.About-container {
  display: flex;
  flex-direction: column;
}

.About-part {
  width: 100%
}

.About-text {
  padding: 1em 2em 2em;
  font-size: 18px;
  font-weight: 300;
}

.About-text p {
  margin-top: 1em;
}

.About-text a, .Active-link {
  text-decoration: underline;
}

.Image {
  background-size: cover;
  min-height: 300px;
}

.About-input-container {
  display: flex;
  width: 100%;
  margin: 1em 0 1.5em;
}

.About-newsletter-input {
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  border: 1px solid black;
  padding: 10px 20px;
  flex: 4;
  height: 50px;
  font-size: 18px;
}

.About-newsletter-button {
  flex: 2;
  border-radius: 10px;
  background: black;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  outline: none;
  border: none;
  cursor: pointer;
  font-size: 20px;
  text-wrap: nowrap;
}

.Canvas-image {
  flex: 1;
  background-repeat: no-repeat;
  cursor: move;
  position: relative;
}

.Canvas {
  height: calc(100vh - 175px);
  width: 100%;
  overflow: auto;
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 30px;
  padding: 10px;
}

.Canvas-gallery {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1;
  background: white;
  padding: 20px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-x: auto;
  overflow-y: hidden;
}

.Canvas-gallery-item {
  width: 55px;
  height: 55px;
  margin-right: 10px;
  cursor: pointer;
}

.Canvas-gallery-item:last-child {
  margin-right: 0;
}

.Canvas-gallery-layers {
  background: #E0F4FD;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px 10px;
  margin-right: 10px;
  height: 62px;
}

.Canvas-gallery-layer-item {
  width: 45px;
  height: 45px;
  margin-right: 10px;
  cursor: pointer;
}

.Canvas-gallery-layer-item:last-child {
  margin-right: 0;
}

.Canvas-gallery-layer-item.active {
  width: 50px;
  height: 50px;
}

.Zoom-buttons-container {
  position: fixed;
  bottom: 120px;
  right: 10px;
  width: 41px;
  height: 82px;
  background: white;
  padding: 0 5px;
  cursor: pointer;
  box-shadow: 0 0 5px #C7C7C7;
}

.Zoom-button {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 35px;
  color: #C7C7C7;
  user-select: none;
}

.Zoom-button:first-child {
  border-bottom: 1px solid #C7C7C7;
}

.Loader {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.Image-active {
  border: 5px solid #E0F4FD;
  border-radius: 4px;
}

.Footer {
  height: 18px;
  background: black;
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  color: white;
  font-size: 10px;
  line-height: 18px;
  font-weight: 300;
}

.desktop-only {
  display: none;
}

@media screen and (min-width: 1024px) {
  .mobile-only {
    display: none;
  }

  .desktop-only {
    display: inherit;
  }

  .Canvas {
    height: calc(100vh - 185px);
  }

  .About-container {
    flex-direction: row;
  }

  .About-part {
    width: 50%;
  }

  .About-text {
    padding: 4em 3em;
    font-size: 20px;
    display: flex;
    flex-direction: column-reverse;
  }

  .About-text p {
    margin-bottom: 2em;
    margin-top: 0;
  }

  .Image {
    min-height: 100%
  }

  .About-input-container {
    margin: 0;
  }

  .About-newsletter-input {
    font-size: 20px;
    height: 62px;
  }

  .About-newsletter-button {
    font-size: 25px;
    height: 62px;
  }

  .Canvas-gallery {
    height: 110px;
  }

  .Canvas-gallery-item {
    width: 78px;
    height: 78px;
    margin-right: 15px;
  }

  .Canvas-gallery-layers {
    margin-right: 15px;
    height: 85px;
  }

  .Canvas-gallery-layer-item {
    width: 62px;
    height: 62px;
  }

  .Canvas-gallery-layer-item.active {
    width: 70px;
    height: 70px;
  }

  .Zoom-buttons-container {
    bottom: 130px;
    right: 30px;
  }
}