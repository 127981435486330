@import-normalize; /* bring in normalize.css styles */

@font-face {
  font-family: Quasimoda;
  src: url(./fonts/quasimoda-regular.otf);
}

@font-face {
  font-family: Quasimoda;
  src: url(./fonts/quasimoda-light.otf);
  font-weight: 300;
}

@font-face {
  font-family: Quasimoda;
  src: url(./fonts/quasimoda-medium.ttf);
  font-weight: bold;
}

div, section, article, a, p, h1, h2, h3, h4, ul, ol, header, footer, input, button {
  box-sizing: border-box;
}

body, html {
  margin: 0;
  padding: 0;
  font-family: Quasimoda, 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
  color: inherit;
}

input, textarea, button {
  font-family: Quasimoda, 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
